<template>
  <CContainer class="d-flex align-items-center min-vh-100">
    <CRow class="w-100 justify-content-center">
      <CCol md="6">
        <div class="clearfix">
          <h1 class="float-left display-3 mr-4">{{ $t('httpCodes.internalServerError') }}</h1>
          <h4 class="pt-3">{{ $t('errors.weHaveAProblem') }}</h4>
          <p class="text-muted">{{ $t('errors.unavailable') }}</p>
        </div>
        <CInput
          class="mb-3"
          :placeholder="$t('common.whatAreYouLooking')"
        >
          <template #prepend-content><CIcon name="cil-magnifying-glass"/></template>
          <template #append>
            <CButton color="info">{{ $t('common.search') }}</CButton>
          </template>
        </CInput>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
export default {
  name: 'Page500'
}
</script>
